import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { ChangePasswordModel } from './changepassword.model';
import { loginService } from './login.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';
import { AlertasComponent } from 'src/app/components/alertas.component';
import { AppComponent } from '../app.component';

var appComponent: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change.password.html',
  styleUrls: ['./login.component.css'],
  providers: [loginService]
})
export class ChangePassword {
  selectedChange: ChangePasswordModel;
  loginForm: FormGroup;

  @Output() logged: EventEmitter<any> = new EventEmitter();


  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;

  constructor(private builder: FormBuilder,
      private loginService: loginService, 
      private _snackBar: MatSnackBar,
      private _bottomSheet: MatBottomSheet,
      private router: Router) {
        this.selectedChange = new ChangePasswordModel();
}

ngOnInit() {
    this.loginForm = this.builder.group({
        'username': [ appComponent.usuario.sub],        
        'password': [ this.selectedChange.password, Validators.required ],
        'passwordnew':[ this.selectedChange.passwordnew, Validators.required ],


    }, { 
            validators: (formGroup: FormGroup): ValidationErrors | null => {
                const data = formGroup.getRawValue();
                let validationErrors = {

                };

                return validationErrors;
            } 
    });
    
  }

  onSubmit(formData: ChangePasswordModel) {
    this._proc = true;
    if (this.loginForm.valid) {
        this.loginService.changePassword(formData).subscribe((resp) => {
            this._proc = false;
            if(resp.status==500){
              this.resultError = resp["message"].substring(resp["message"].indexOf( ":" ) +2,  resp["message"].length);
              this.openNotificationDanger(this.resultError);
            }else{       
              this._status = resp.body.status !== 200;
              this.resultError = null;

              if (this._status) {
                  this.resultError = 'changePassword error ' + resp.status;  
              } else {
                sessionStorage.setItem("ischangePassword", "true");
                this.logged.emit(null);
              }
            }
        });

    }
  }





  handleKeyPress(e, formData) {
    if (e.key === 'Enter') {
      this.onSubmit(formData);
    }
  }
  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
        duration: 2000,
        panelClass: 'dangerSnackBar',
    });
 }   
}

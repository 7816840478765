import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';

import { environment } from '../../../../environments/environment';

import { NotificacionModel } from './notificacion.model';

@Injectable({ providedIn: 'root' })
export class NotificacionService {
    private notificacionUrl = '';  // URL to web api

    constructor(private http: HttpClient) {
        this.notificacionUrl = `${environment.dataServiceUrl}/Mensajeria/Notificacion`;
    }

    getNotificacion(row: NotificacionModel): Observable<NotificacionModel> {
        const sUrl = `${this.notificacionUrl}/${row.secuencia}`;

        return this.http.get<NotificacionModel>(sUrl).pipe(
            retry(3),
            tap(() => this.log('fetched Notificacion')),
            catchError((error) => this.handleError('getNotificacion', error))
        );
    }

    getNotificacionAll(): Observable<any> {
        return this.http.get<any>(this.notificacionUrl, { }).pipe(
            retry(3),
            tap(row => this.log('fetched Notificacion')),
            catchError((error) => this.handleError('getNotificacionList', error))
        );
    }

    getNotificacionList(
                filter: {
                    value: any,
                    condition: string,
                    column: string
                },
                paginator: MatPaginator,
                sort: MatSort): Observable<any> {

        const params: any = {
            filtroValor: filter.value,
            filtroCondicion: filter.condition,
            filtroColumna: filter.column,
            paginaTamano: paginator.pageSize.toString(),
            paginaNumero: (paginator.pageIndex + 1).toString(),
            ordenColumna: sort.active || '',
            ordenTipo: sort.direction || ''
        };

        const sUrl = `${this.notificacionUrl}/Pagina`;

        return this.http.get<any>(sUrl, { params }).pipe(
            retry(3),
            tap(row => this.log('fetched Notificacion')),
            catchError((error) => this.handleError('getNotificacionList', error))
        );
    }

    addNotificacion(row: NotificacionModel): Observable<NotificacionModel> {
        return this.http.post<NotificacionModel>(this.notificacionUrl, [NotificacionModel.clone(row)]).pipe(
            retry(3),
            tap((rrow: NotificacionModel) => this.log(`added Notificacion w/ id=/${row.secuencia}`)),
            catchError((error) => this.handleError('addNotificacion', error))
        );
    }

    updateNotificacion(row: NotificacionModel, original: NotificacionModel): Observable<NotificacionModel> {
        return this.http.put<NotificacionModel>(this.notificacionUrl, NotificacionModel.clone(row)).pipe(
            retry(3),
            tap(_ => this.log(`update Notificacion id=/${row.secuencia}`)),
            catchError((error) => this.handleError('updateNotificacion', error))
        );
    }

    saveNotificacion(row: NotificacionModel, original: NotificacionModel): Observable<NotificacionModel> {
        if (row._estado === 'N') {
            return this.addNotificacion(row);
        } else {
            return this.updateNotificacion(row, original);
        }
    }

    deleteNotificacion(row: NotificacionModel): Observable<NotificacionModel> {
        const sUrl = `${this.notificacionUrl}/${row.secuencia}`;

        return this.http.delete(sUrl).pipe(
            retry(3),
            tap(_ => this.log(`filter Notificacion id=/${row.secuencia}`)),
            catchError((error) => this.handleError('deleteNotificacion', error))
        );
    }

    saveRowsNotificacion(rows: Array<NotificacionModel>): Observable<any> {
        const _rows = rows.map((row) => NotificacionModel.clone(row));
        return this.http.post<any>(this.notificacionUrl, _rows).pipe(
            retry(3),
            tap((rrows: NotificacionModel) => this.log(`pasted rows in Notificacion `)),
            catchError((error) => this.handleError('addNotificacion', error))
        );
    }

    getById(user){
        let url = `${environment.dataServiceUrl}/Identity/Usuario/${user}`;
     
        return this.http.get<any>(url).pipe(
            tap(row => this.log('fetched Notificacion')),
            catchError((error) => this.handleError('getNotificacionList', error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

          // TODO: send the error to remote logging infrastructure
          console.error(result.error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${result.message}`);

          // Let the app keep running by returning an empty result.
          return of(result);
    }

    /** Log a NotificacionService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`NotificacionService: ${message}`);
        console.log(`NotificacionService: ${message}`);
    }

}

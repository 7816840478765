import { Component, ViewChild, HostListener, Input } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { FormGroup, FormBuilder, ValidationErrors, FormControl } from '@angular/forms';
import { funcionalidadesModel } from '../../modulos/funcionalidades.model';
import { isnull } from '../../components/powerbuilder.functions';
import { moduloModel } from '../../modulos/modulo.model';
import { startWith, switchMap, map, ignoreElements } from 'rxjs/operators';
import { funcionalidadesService } from '../../modulos/funcionalidades.service';
import { subirAnimationMenu } from '../../animations/animations';
import { Observable, Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { moduloService } from '../../modulos/modulo.service'
import { AppService } from '../../app.service';
import * as jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material';
import { CambiarClaveDialog } from '../../login/cambiarclave/cambiarclave.dialog';
import { loginService } from '../../login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material';
import { Location } from '@angular/common';

import { YamiCodeSocketComponent } from '../../components/yami-code-socket/yami-code-socket.component';
import { NotificacionDialog } from '../../mensajeria/gr-gestion/notificacion/notificacion.dialog';
import { NotificationComunicationService } from '../../services/notificacionescomunication.service';
import { MiInformacionDialog } from '../miinformacion/miinformacion.dialog';

declare var appComponent: any;

@Component({
  selector: 'app-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.css'],
  providers: [funcionalidadesService],
  animations: [subirAnimationMenu]
})
export class SideNavMenuComponent {

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('sidenav', { static: false }) public sidenav: MatSidenav;

  get usuario(): any {
    var _usuario;
    try {
      _usuario = jwt_decode(sessionStorage.getItem('token'));
    } catch (error) {
      _usuario = {};
    }
    return _usuario;
  }

  activeLang: string;
  activeImg: string;
  filtroForm: FormGroup;
  _search = false;
  _panel = false;
  opened = false;
  verIcono = true;
  _funcionShow = false;
  _funcionPanel = true;
  _funcionModulo = true;
  itemActivo: funcionalidadesModel;
  moduloActivo: any;
  selectedModulo: moduloModel = null;
  selectedGrupos: string = '';
  filteredFuncionalidades: funcionalidadesModel[];
  grupos: Array<string> = [];
  filteredFuncionalidades_Identity: Array<any> = [];
  modulos: Array<moduloModel> = [];
  _gruposShow = false;
  userTokenData;
  filteredModules: Observable<moduloModel[]>;
  myControl = new FormControl();
  selectedFuncionalidades: funcionalidadesModel = null;
  loadingModules = true;
  loadingFuncionalidades = false;
  _funcionGrupo = false;
  _funcionalidad = false;
  tipoLogoMenu = 'more_vert';
  loadingRouteConfig: boolean;
  verLista = false;

  funcionalidades: Array<funcionalidadesModel> = [];

  numberNotificacion: number = 0;
  rows: [] = [];
  subscription: Subscription;

  constructor(private router: Router,
              private builder: FormBuilder,
              private funcionalidadesService: funcionalidadesService,
              private moduloService: moduloService,
              private appService: AppService,
              public dialog: MatDialog,
              private loginService: loginService,
              private translate: TranslateService,
              private location: Location,
              private notificationComunicationService: NotificationComunicationService) {

    this.subscription = this.notificationComunicationService.getChanges().subscribe(activate => {
      if (activate) {
        this.notificacionSocket();
      }
    });

    if (sessionStorage.getItem('Panel') == 'True') {
      this.itemActivo = new funcionalidadesModel();
      this.itemActivo.funcionalidadNombre = sessionStorage.getItem('Funcionalidad');
      this.itemActivo.grupo = sessionStorage.getItem('Grupo');
      this.moduloActivo = new moduloModel();
      this.moduloActivo.moduloNombre = sessionStorage.getItem('Modulo');
      this._funcionShow = true;
    };

    this.activeLang = 'es-mx';
    this.activeImg = 'btn-lang-mx';
    this.filtroForm = this.builder.group({
      'filtroTexto': [''],
      'filtroTexto_codigo': [''],
    }, {
      validators: (formGroup: FormGroup): ValidationErrors | null => {
        const data = formGroup.getRawValue();
        let validationErrors = {};
        return validationErrors;
      }
    });
  }

  socket : YamiCodeSocketComponent;

  ngOnInit() {
    if (this.location.path() !== '/') {
      setTimeout(() => {  this._funcionPanel = true; }, 10)
      this._panel = true;
    } else {
      setTimeout(() => {  this._funcionPanel = true; }, 10)
      this._panel = false;
    }

    this.filtroForm.valueChanges.subscribe((data) => {
      this.filtroForm.patchValue({
      }, { emitEvent: false, onlySelf: true });
    });

    this.filtroForm.controls.filtroTexto.valueChanges
      .pipe(
        startWith(''),
        switchMap((data) => {
          if (data) {
            return this.funcionalidadesService.filterFuncionalidad_Identity(data, Number(this.usuario.rl));
          } else {
            return [];
          }
        })
      ).subscribe((data) => {
        this.filteredFuncionalidades_Identity = data.rows;
      });

      this.notification();
      this.socket = new YamiCodeSocketComponent(null);

  }
  ngAfterViewInit() {
    this.notification();
    this.loadingModules = true;
    this.loadNavigation();
    this.router.events
      .subscribe((event) => {

        if (event instanceof NavigationStart) {
          this.loadingRouteConfig = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loadingRouteConfig = false;
        }
      });

  }

  volverMenu() {
    this.router.navigateByUrl('/');
    this.filtroForm.patchValue({ filtroTexto: '' })
    this._search = false;
    this._panel = false;
    this.opened = false;
    this.verIcono = true;

    this._funcionShow = false
    this._funcionPanel = true;

    this._funcionModulo = true;

    this.itemActivo = new funcionalidadesModel();
    this.itemActivo.funcionalidadNombre = '';
    if (!isnull(this.moduloActivo)) { this.moduloActivo._funcionalidadesShow = false };
    this.selectedModulo = new moduloModel();
    this.selectedModulo.moduloNombre = '';
    this.selectedGrupos = '';
    this.grupos = [];

    if (!isnull(this.selectedModulo)) {
      this.selectedModulo._funcionalidadesShow = false;
      this.selectedModulo._funcionalidades = false
    };
    this.filteredFuncionalidades = [];

    sessionStorage.setItem('Panel', 'False');
    sessionStorage.removeItem('Funcion;alidad');
    sessionStorage.removeItem('Grupo');
    sessionStorage.removeItem('Modulo');
  }

  public setModulos(modulos: Array<moduloModel>) {
    this.loadingModules = false;
    this.selectedModulo = null;
    this.modulos = modulos;
  }

  selectModuloPanel(e, modulo: moduloModel) {
    this.opened = false;
    this._search = false;
    this.verIcono = true;
    this.selectModulo(e, modulo);
  }

  selectModulo(e, modulo: moduloModel) {
    if (this.selectedModulo === modulo) {
      this.selectedModulo._funcionalidadesShow = !this.selectedModulo._funcionalidadesShow;
    } else {
      if (this.selectedModulo) {
        this.selectedModulo._funcionalidadesShow = false;
      };
      this.selectedModulo = modulo;
      this.moduloActivo = modulo;
      modulo._funcionalidades = true;
      this.loadingFuncionalidades = true;
      this.blockUI.start('Cargando...');
      this.funcionalidadesService.getFuncionalidades(modulo).subscribe((data) => {
        this.blockUI.stop();
        if (!data.error) {
          this.funcionalidades = data;
          this.loadingFuncionalidades = false;
          modulo._funcionalidades = true;
          modulo._funcionalidadesShow = true;
          this.selectedGrupos = null;
          this._gruposShow = false;
          this.grupos = [...new Set(this.funcionalidades.map((x) => x.grupo))];
          this.selectedGrupos = '';
          this._funcionGrupo = true;
        } else {
          this.selectedModulo = null;
          this.selectModulo(e, modulo);
        }

      });
    }
    this._funcionModulo = false;
    this._funcionalidad = false;
    this._search = false;
    this.filteredFuncionalidades = [];
  }

  selectGrupo(e, grupo: string) {
    if (this.selectedGrupos === grupo) {
      this._gruposShow = !this._gruposShow;
    } else {
      this._gruposShow = true;
      this.selectedGrupos = grupo;
      this.filteredFuncionalidades = this.funcionalidades.filter((x) => x.grupo === this.selectedGrupos);
    }
    this._funcionalidad = true;
  }

  selectFuncionalidad(e, modulo: moduloModel, funcionalidad: funcionalidadesModel) {
    this._funcionPanel = false;
    window['funcionalidadActiva'] = { modulo, funcionalidad };
    this.urlm = this.selectedGrupos.replace(/ /g, '');
    this.urlm = this.urlm.toLowerCase();
    this.urlm = this.MaysPrimera(this.urlm);
    this.router.navigateByUrl(`/app${this.moduloActivo.rutaEjecutable}/${this.urlm}/${funcionalidad.ventanaNombre}`);
    this.itemActivo = funcionalidad;
    this._panel = true;
    this.retrasoRuta();
    sessionStorage.setItem('Panel', 'True');
    sessionStorage.setItem('Modulo', this.moduloActivo.moduloNombre);
    sessionStorage.setItem('Grupo', this.itemActivo.grupo);
    sessionStorage.setItem('Funcionalidad', this.itemActivo.funcionalidadNombre);
  }

  onSelectFuncionalidad_busqueda(opt: any, panel?: boolean) {
    if (panel) { this._panel = panel };
    this._funcionPanel = false;

    this.modulos.forEach(modulo => {
      if (modulo.moduloCodigo == opt.moduloCodigo && modulo != this.selectedModulo) {
        this.selectModulo('', modulo);
      }
    });
    this.blockUI.start('Cargando...');
    this.funcionalidadesService.getFuncionalidades(this.moduloActivo).subscribe((data) => {
      this.blockUI.stop();
      if (!data.error) {
        data.forEach(funcionalidad => {
          if (funcionalidad.funcionalidadCodigo == opt.funcionalidadCodigo) {
            this.itemActivo = funcionalidad;
          }
        });
        if (this.itemActivo.grupo != this.selectedGrupos) { this.selectGrupo('', this.itemActivo.grupo) };
        this.router.navigateByUrl(`/app${this.moduloActivo.rutaEjecutable}/${this.itemActivo.grupo}/${this.itemActivo.ventanaNombre}`);
        this.retrasoRuta();
      } else {
        this.onSelectFuncionalidad_busqueda(opt, panel);
      }

    });
  }

  loadNavigation() {
    if (sessionStorage.getItem('token') && this.modulos.length < 1) {
      this.blockUI.start('Cargando...');
      this.moduloService.getModulos().subscribe((data) => {
        this.blockUI.stop();
        if (data instanceof Array) {
          this.modulos = data;
          this.filteredModules = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
          this.setModulos(data);
        }
      });
    } else if (this.modulos.length > 0) {
      this.loadingModules = false;
    }
  }


  private _filter(value: string): moduloModel[] {
    const filterValue = value.toString().toLowerCase();
    return this.modulos.filter(_module => _module.moduloNombre.toLowerCase().includes(filterValue));
  }


  volverHome() {
    this.router.navigateByUrl('/');
    this.filtroForm.patchValue({ filtroTexto: '' })
    this._search = false;
    this._funcionShow = false
    this._funcionModulo = false;
    this._funcionalidad = true;
    this._funcionPanel = true;
    sessionStorage.setItem('Panel', 'False');
    sessionStorage.removeItem('Funcion;alidad');
    sessionStorage.removeItem('Grupo');
    sessionStorage.removeItem('Modulo');
  }

  loadUserTokenInfo() {
    if (sessionStorage.getItem('token')) {
      this.userTokenData = jwt_decode(sessionStorage.getItem('token'));
      console.log(this.userTokenData);
      if (this.userTokenData) {
        if (Number(this.userTokenData.rl)) {
          this.appService.getByIdIdentityRol(Number(this.userTokenData.rl)).subscribe((data) => {
            this.userTokenData.rolDetail = data;
          });
        }
        this.appService.getByIdSucursal(Number(this.userTokenData.mpr), Number(this.userTokenData.cst)).subscribe((data) => {
          this.userTokenData.sucursalDetail = data;
        });
      }
    }
  }

  cambioClave() {
    if (sessionStorage.getItem('token')) {
      this.userTokenData = jwt_decode(sessionStorage.getItem('token'));
      if (this.userTokenData) {

        const dialogRef = this.dialog.open(CambiarClaveDialog, {
          data: {
            username: this.userTokenData.sub,
            password: '',
            passwordnew: ''
          }
        });
        dialogRef.afterClosed().subscribe(result => { });
      }
    }
  }

  miInformacion() {
    if (sessionStorage.getItem('token')) {
      this.userTokenData = jwt_decode(sessionStorage.getItem('token'));
      if (this.userTokenData) {

        const dialogRef = this.dialog.open(MiInformacionDialog, {
          data: {
            username: this.userTokenData.sub
          }
        });
        dialogRef.afterClosed().subscribe(result => { });
      }
    }
  }

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private urlm: string;

  showRouterOutlet() {
    if (this.router.url === "/") {
      if (this.showBodyMenu()) {
        return false;
      } else {
        if (this.showModuleOptMenu()) {
          return false;
        } else {
          return this._funcionModulo;
        }

      }

    } else {
      return true;
    }

  }
  showModuleOptMenu() {
    return !this._funcionModulo;
  }

  showBodyMenu() {
    if (this.router.url === "/") {
      this._funcionPanel = true;
      return this._funcionModulo;
    } else if (this.location.path() !== '/') {
      this._funcionPanel = false;
      return this._funcionModulo;
    } else {
      return false;
    }
  }

  public cerrarSesion() {
    this.blockUI.start('Cargando...');
    this.loginService.logout().subscribe((resp) => {
      this.blockUI.stop();
      sessionStorage.clear();
      this.router.navigate(['']);
    });
  }

  cambiarIconoMenu() {
    this.verIcono = !this.verIcono;
    if (this.tipoLogoMenu === 'more_vert') {
      this.verLista = false;
      this.tipoLogoMenu = 'menu';
    } else {
      setTimeout(() => {
        this.verLista = true;
      }, 1);
      this.tipoLogoMenu = 'more_vert';
    }
  }

  retrasoRuta() {
    setTimeout(() => {
      this._funcionShow = true;
    }, 1000);
  }

  setLanguage(language: string, img: string) {
    this.activeLang = language;
    this.activeImg = img;
    this.translate.use(language);
  }

  notification() {
    this.funcionalidadesService.getNumberNotification().subscribe(
      (resp: any) => {
        this.numberNotificacion = resp;
      }
    );
  }

  notificacionSocket() {
    this.notification();
    //this.listNotification();
  }


  listNotification() {

    this.funcionalidadesService.listNotification(String(appComponent.usuario.sub)).subscribe(
      (resp: any) => {
        this.rows = resp.rows;
        this.rows.forEach((obj:any)=>{
          this.funcionalidadesService.getUsuario(obj.usuarioFrom).subscribe((rst)=>{
            obj.usuarioFromNombre = rst.nombre;
          });
        });
      }
    );
  }

  detailNotification(notification: any) {
    const dialogRef = this.dialog.open(NotificacionDialog, {
      data: notification
    });
    dialogRef.afterClosed().subscribe(result => {
      this.notification();
    });
  }

  viewNotification(){
    this.router.navigate(['/app/mensajeria/Gestion/GestionNotificacion']);
  }
}

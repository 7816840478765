import { Component, HostListener, OnInit } from '@angular/core';
import { funcionalidadesService } from './modulos/funcionalidades.service';
import { subirAnimationMenu } from './animations/animations';
import * as jwt_decode from 'jwt-decode';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [funcionalidadesService],
  animations: [subirAnimationMenu]
})

export class AppComponent {

  @BlockUI() blockUI: NgBlockUI;

  get usuario(): any {
    var _usuario;
    try {
      _usuario = jwt_decode(sessionStorage.getItem('token'));
    } catch (error) {
      _usuario = {};
    }
    return _usuario;
  }

  get isLogin(): boolean {
    return JSON.parse(sessionStorage.getItem('isLogin') || 'false');
  }
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false;
  }
  constructor(private router: Router) {
    window['appComponent'] = this;
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  ngAfterViewInit() {
    
  }
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.blockUI.start('Cargando Información...'); // Start blocking
      console.log('nStart');
    }
    if (event instanceof NavigationEnd) {
      this.blockUI.stop();
      console.log('nStop');
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.blockUI.stop();
      console.log('nCancel');
    }
    if (event instanceof NavigationError) {
      this.blockUI.stop();
      console.log('nError');
    }
  }
}

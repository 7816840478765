import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

import { environment } from '../../environments/environment';

import { funcionalidadesModel } from './funcionalidades.model';
import { moduloModel } from './modulo.model';

@Injectable({ providedIn: 'root' })
export class funcionalidadesService {
    private rolUrl = '';  // URL to web api
    private notification = '';

    get usuario(): any {
      return jwt_decode(sessionStorage.getItem("token"))
    }
      
    constructor(private http: HttpClient) {
        this.rolUrl = `${environment.loginServiceUrl}/Autorizacion/ModuloRolFuncionalidad`;
        this.notification = `${environment.dataServiceUrl}/Mensajeria/Notificacion`;
    }

    getFuncionalidades(modulo: moduloModel): Observable<any> {
      const _rolUrl = `${this.rolUrl}/${this.usuario.rl}/${modulo.moduloCodigo}`;

      return this.http.get<funcionalidadesModel[]>(_rolUrl).pipe(
          retry(3),
          tap((data) => this.log(`funcionalidadess`)),
          catchError((error) => this.handleError('funcionalidades', error))
      );
  }

    getUsuario(val: string): Observable<any> {
      const sUrl = `${environment.dataServiceUrl}/Identity/Usuario/${val}`;
      return this.http.get<any>(sUrl).pipe(
          retry(3),
          tap(_ => this.log(`filter getUsuario id=${val}`)),
          catchError((error) => this.handleError('filterdddw_sucursales', error))
      );
  }

  filterFuncionalidad_Identity(val: string, rol: number): Observable<any> {
      const sUrl = `${environment.dataServiceUrl}/Identity/FuncionalidadRol/${rol}/Pagina`;
      const params: any = {
          paginaTamano: 10,
          paginaNumero: 1,
          filtroValor: val || ' ',
          ordenColumna: 'funcionalidad_nombre',
          filtroCondicion: 'like',
          filtroColumna: 'funcionalidad_nombre',
          ordenTipo: 'asc'
      };

      return this.http.get<any>(sUrl, { params: params }).pipe(
          retry(3),
          tap(_ => this.log(`filterFuncionalidad_Identity id=${val}`)),
          catchError((error) => this.handleError('filterFuncionalidad_Identity', error))
      );
  }

  getNumberNotification() {
      let url = `${this.notification}/Pendientes`
      return this.http.get(url).pipe(
          tap((data) => this.log(`funcionalidadess`)),
          catchError((error) => this.handleError('funcionalidades', error))
      );
  }

  listNotification(usuario: string) {
      let params = new HttpParams();
      params = params.append('paginaNumero', '1');
      params = params.append('paginaTamano', '5');
      params = params.append('ordenTipo', 'asc');
      params = params.append('filtroColumna', 'usuario_to');
      params = params.append('filtroCondicion', 'like');
      params = params.append('filtroValor', usuario);

      params = params.append('ordenColumna', 'secuencia desc, estado');
      


      const sUrl = `${this.notification}/Pagina`;
      return this.http.get<any>(sUrl, { params }).pipe(

          tap(row => this.log('fetched CarpetaReferenciasPreguntas')),
          catchError((error) => this.handleError('getCarpetaReferenciasPreguntasList', error))
      );
  }

    private handleError(operation = 'operation', result?: any) {

          // TODO: send the error to remote logging infrastructure
          console.log(result.error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${result.message}`);

          // Let the app keep running by returning an empty result.
          return of(result);
    }

    /** Log a INVCO_BienesService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`loginService: ${message}`);
        console.log(`funcionalidadesService: ${message}`);
    }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './app.shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ChangePassword } from './login/app-change-password';
import { BlockUIModule } from 'ng-block-ui';
import {CambiarClaveDialog} from './login/cambiarclave/cambiarclave.dialog';
import { SideNavMenuComponent } from './nav-menu/side-nav/side-nav-menu.component';
import { YamiCodeSocketComponent } from './components/yami-code-socket/yami-code-socket.component';
import { LoginDialog } from './login/logindialog/logindialog.dialog';
import { NotificacionDialog } from './mensajeria/gr-gestion/notificacion/notificacion.dialog';
import { MAT_DATE_LOCALE } from '@angular/material';
import { MfaDialog } from './login/mfa/mfa.dialog';
import { MiInformacionDialog } from './nav-menu/miinformacion/miinformacion.dialog';

@NgModule({
  declarations: [
    MiInformacionDialog,
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    ChangePassword,
    CambiarClaveDialog,
    SideNavMenuComponent,
    YamiCodeSocketComponent,
    LoginDialog,
    MfaDialog,
    NotificacionDialog
  ],
  entryComponents: [
    MiInformacionDialog,
    MfaDialog,
    CambiarClaveDialog,
    YamiCodeSocketComponent,
    LoginDialog,
    NotificacionDialog
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BlockUIModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    SideNavMenuComponent,
    YamiCodeSocketComponent,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },]
})
export class AppModule {
}

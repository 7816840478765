//Global Powerbuilder Methods
export function beep(...args): number {
    throw "Beep is a powerbuilder native method don't implemented";
}

export function commandparm(...args): any {
    throw "CommandParm is a powerbuilder native method don't implemented";
}

export function close(...args): number {
    throw "Close is a powerbuilder native method don't implemented";
}

export function closewithreturn(...args): number {
    throw "Close is a powerbuilder native method don't implemented";
}

export function date(...args): Date {
    throw "Date is a powerbuilder native method don't implemented";
}

export function datetime(...args): Date {
    throw "Date is a powerbuilder native method don't implemented";
}

export function daysafter(...args): number {
    throw "DaysAfter is a powerbuilder native method don't implemented";
}

export function double(value: any): number {
    return parseFloat(value);
}

export function fileexists(...args): boolean {
    throw "PrintSetup is a powerbuilder native method don't implemented";
}

export function integer(value: any): number {
    return parseInt(value);
}

export function isnull(value: any): boolean {
    return value === undefined || value === null;
}

export function isvalid(value: any): boolean {
    return !isnull(value);
}

export function keydown(...args): boolean {
    throw "KeyDown is a powerbuilder native method don't implemented";
}

export function left(value: string, length: number): string {
    return value.substr(0, length);
}

export function lefta(value: string, length: number): string {
    return value.substr(0, length);
}

export function len(value: string): number {
    return value.length;
}

export function lena(value: string): number {
    return value.length;
}

export function messagebox(arg1: any, arg2: any, arg3: any, arg4: any, arg5: any): any;
export function messagebox(arg1: any, arg2: any, arg3: any, arg4: any): any;
export function messagebox(arg1: any, arg2: any, arg3: any): any;
export function messagebox(arg1: any, arg2: any): any;

export function messagebox(...args): any {
    alert(args[0]);
    console.log('Messagebox: will be implemented Mat-Dialog');
    return null;
}

export function mid(value: string, from: number, length?: number): string {
    return value.substr(from - 1, length);
}

export function mida(value: string, from: number, length?: number): string {
    return value.substr(from - 1, length);
}

export function month(value: Date): number {
    return value.getMonth();
}

export function now(): Date {
    return new Date();
}

export function open(...args) {
    throw "Open is a powerbuilder native method don't implemented";
}

export function openwithparm(...args) {
    throw "OpenWithParm is a powerbuilder native method don't implemented";
}

export function pos(...args): number {
    throw "Pos is a powerbuilder native method don't implemented";
}

export function posa(...args): number {
    throw "PosA is a powerbuilder native method don't implemented";
}

export function postevent(...args) {
    throw "Postevent is a powerbuilder native method don't implemented";
}

export function printsetup(...args) {
    throw "PrintSetup is a powerbuilder native method don't implemented";
}

export function profilestring(...args): any {
    throw "profilestring is a powerbuilder native method don't implemented";
}

export function replace(...args): string {
    throw "ReplaceA is a powerbuilder native method don't implemented";
}

export function replacea(...args): string {
    throw "ReplaceA is a powerbuilder native method don't implemented";
}

export function rgb(r: number, g: number, b: number): number {
    return (r << 16) + (g << 8) + b;
}

export function righta(value: string, length: number): string {
    return value.substring(-length);
}

export function round(...args): number {
    throw "Round is a powerbuilder native method don't implemented";
}

export function setnull(...args) {
    throw "SetNull is a powerbuilder native method don't implemented";
}

export function string(arg1: any, arg2: any): any;
export function string(arg1: any): any;

export function string(...args): string {
    var result = args[0].toString();
    if(args.length > 1) {
        console.log('String Format don\'t implemented');
    }
    return result;
}

export function today(): Date {
    return new Date();
}

export function trim(value: string): string {
    return value.trim();
}

export function upper(value: string): string {
    return value || value.toUpperCase();
}

export function upperbound(...args): number {
    throw "UpperBound is a powerbuilder native method don't implemented";
}

export function year(value: Date): number {
    return value.getFullYear();
}


import {Component, Output, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, ValidationErrors} from '@angular/forms';
import {loginModel} from './login.model';
import {loginService} from './login.service';
import {Router} from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet, MatDialog} from '@angular/material';
import {AlertasComponent} from 'src/app/components/alertas.component';
import {version} from '../../../package.json';
import {
  bajarAnimation,
  bajarAnimationMedWait,
  derAIzAderButAnimation, derAIzAderButAnimationBajarWait,
  HijoBajIzqDerButtAnimation,
  subirAnimationWait
} from '../animations/animations';
import {SideNavMenuComponent} from '../nav-menu/side-nav/side-nav-menu.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CambiarClaveDialog } from './cambiarclave/cambiarclave.dialog';
import { MfaDialog } from './mfa/mfa.dialog';
 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [loginService],
  animations: [derAIzAderButAnimationBajarWait, derAIzAderButAnimation, subirAnimationWait,
    HijoBajIzqDerButtAnimation, bajarAnimation, bajarAnimationMedWait]
})
export class LoginComponent implements OnInit {
  selectedLogin: loginModel;
  loginForm: FormGroup;

  @Output() logged: EventEmitter<any> = new EventEmitter();
  @BlockUI() blockUI: NgBlockUI;

  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;
  version: string = version;

  constructor(private builder: FormBuilder,
              private loginService: loginService,
              private _snackBar: MatSnackBar,
              private _bottomSheet: MatBottomSheet,
              private router: Router,
              public sideNavMenu: SideNavMenuComponent,
              public dialog: MatDialog) {
    this.selectedLogin = new loginModel();
  }

  ngOnInit() {
    this.loginForm = this.builder.group({
      'username': [this.selectedLogin.username, Validators.required],
      'password': [this.selectedLogin.password, Validators.required]
    }, {
      validators: (formGroup: FormGroup): ValidationErrors | null => {
        const data = formGroup.getRawValue();
        let validationErrors = {};

        return validationErrors;
      }
    });

  }

  onSubmit(formData: loginModel) {
    this.sideNavMenu.volverMenu();
    this._proc = true;
    if (this.loginForm.valid) {
      this.blockUI.start('Cargando...');
      this.loginService.login(formData).subscribe((resp) => {
        this.blockUI.stop();
        this._proc = false;
        if (resp.status == 500) {
          this.resultError = resp['message'].substring(resp['message'].indexOf(':') + 2, resp['message'].length);
          this.openNotificationDanger(this.resultError);
        } else if(resp.status == 401 || resp.status == 412 || resp.status == 403 || resp.status == 404){
          this.resultError = resp['message'];
          this.openNotificationDanger(this.resultError);
        } else {
          this._status = resp.body.status !== 200;
          this.resultError = null;

          if (this._status) {
            this.resultError = 'login error ' + resp.status;
          } else {
            if(window['appComponent'].usuario.chng == "1"){
              sessionStorage.setItem('isLogin', 'false');
              this.cambiarContraseña();
            } else if (window['appComponent'].usuario.mfa == "1") {
              sessionStorage.setItem('isLogin', 'false');
              this.mfa();
            }
            else{
              if(Number(window['appComponent'].usuario.dchng) > 0){
                this.openNotificationInfo(`Quedan ${Number(window['appComponent'].usuario.dchng)} dias para la expiración de la clave`);
              }
              sessionStorage.setItem('isLogin', 'true');
              this.logged.emit(null);
            }
          }
        }
      });
    }
  }

  cambiarContraseña() {
    let dataEnvio = {
      username: window['appComponent'].usuario.sub
    }
    const dialogRef = this.dialog.open(CambiarClaveDialog, {
      data: dataEnvio
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) { }
    });
  }

  handleKeyPress(e, formData) {
    if (e.key === 'Enter') {
      this.onSubmit(formData);
    }
  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }

  openNotificationInfo(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'infoSnackBar',
    });
  }

  mfa() {
    let dataEnvio = {
      username: window['appComponent'].usuario.sub
    }
    const dialogRef = this.dialog.open(MfaDialog, {
      data: dataEnvio
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data > 0) {
        sessionStorage.setItem('isLogin', 'true');
        this.logged.emit(null);
      }else{

      }
    });
  }
}

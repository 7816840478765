import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { CambiarClaveModel } from './cambiarclave.model';

const httpOptions = {
    observe: null,
    params: null,
    headers: new HttpHeaders({
      'Content-Type': 'application/json'  ,
      'username': '',
      'passord': '',
      'passwordnew': ''
    })
  };

@Injectable({ providedIn: 'root' })
export class CambiarClaveService {
    private loginUrl = '';  // URL to web api
    public isLogin = false;
    public token = '';

    constructor(private http: HttpClient) {
        this.loginUrl = `${environment.dataServiceUrl}/Identity/Autenticacion`;
    }

    cambioClave(row: CambiarClaveModel): Observable<HttpResponse<CambiarClaveModel>> {
      httpOptions.headers = new HttpHeaders({
        'Content-Type': 'application/json'  ,
        'username': row.username,
        'password': row.password,
        'passwordnew': row.passwordnew
      });

      httpOptions.observe = "response";

      const sUrl = `${this.loginUrl}/CambioClave`;

      return this.http.post<CambiarClaveModel>(sUrl, {}, httpOptions).pipe(
          tap((resp: HttpResponse<CambiarClaveModel>) => {
            this.log(`login w/ id=${resp.body.username}`);
            return resp;
        }),
          catchError((error) => this.handleError('login', error))
      );
    }

    
    private handleError(operation = 'operation', result?: any) {

          // TODO: send the error to remote logging infrastructure
          console.log(result.error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${result.message}`);

          // Let the app keep running by returning an empty result.
          return of(result);
    }

    /** Log a INVCO_BienesService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`loginService: ${message}`);
        console.log(`LoginService: ${message}`);
    }

}
